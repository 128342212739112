<template>
  <BaseModal
    id="bv-modal-detalhe"
    name="bv-modal-detalhe"
    size="xl"
    :title="broadcast.title && broadcast.title !== undefined ? broadcast.title : '' "
  >
    <div class="content broadcast-html" :style="broadcast.status >= 1 ? '' : 'padding: 0 !important;'">
      <p  v-show="broadcast.status >= 1" class="fonte-data">
        {{ broadcast.send_at | moment("DD/MM/YYYY HH:mm") }}
      </p>
      <div class="flex-2">
        <div v-show="broadcast.status >= 1" class="card-detalhes">
          <p class="title-card">Métricas do Broadcast</p>
          <section class="grid-metrica">
            <div class="content-metrica">
              <p class="title-metrica2">Processados</p>
              <div class="flex">
                <div class="pointer-process"></div>
                <p
                  class="number-metrica"
                  v-if="
                    broadcast.fetched_count === 1 &&
                    broadcast.selected_count !== 1
                  "
                >
                  {{ broadcast.selected_count }}
                </p>
                <p
                  class="number-metrica"
                  v-else-if="broadcast.fetched_count === null"
                >
                  0
                </p>
                <p class="number-metrica" v-else>
                  {{ broadcast.fetched_count }}
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Entregues</p>
              <div class="flex">
                <div class="pointer-enviado"></div>
                <p class="number-metrica">
                  {{ broadcast.delivereds_count }} /
                  {{ broadcast.percentageDelivereds }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Abertos</p>
              <div class="flex">
                <div class="pointer-aberto"></div>
                <p class="number-metrica">
                  {{ broadcast.opens_count }} / {{ broadcast.percentageOpen }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Clicados</p>
              <div class="flex">
                <div class="pointer-clicados"></div>
                <p class="number-metrica">
                  {{ broadcast.clicks_count }} /
                  {{ broadcast.percentageClick }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Spam</p>
              <div class="flex">
                <div class="pointer-spam"></div>
                <p class="number-metrica">
                  {{ broadcast.spams_count }} / {{ broadcast.percentageSpam }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Dropped</p>
              <div class="flex">
                <div class="pointer-dropped"></div>
                <p class="number-metrica">
                  {{ broadcast.droppeds_count }} /
                  {{ broadcast.percentageDroppped }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Bounce</p>
              <div class="flex">
                <div class="pointer-bounce"></div>
                <p class="number-metrica">
                  {{ broadcast.bounceds_count }} /
                  {{ broadcast.percentageBounce }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Unsubscribeds</p>
              <div class="flex">
                <div class="pointer-sairam"></div>
                <p class="number-metrica">
                  {{ broadcast.unsubscribeds_count }} /
                  {{ broadcast.percentageUnsubscribeds }}%
                </p>
              </div>
            </div>
            <div class="content-metrica">
              <p class="title-metrica2">Deferreds</p>
              <div class="flex">
                <div class="pointer-cancel"></div>
                <p class="number-metrica">
                  {{ broadcast.deferreds_count }} /
                  {{ broadcast.percentageDeferreds }}%
                </p>
              </div>
            </div>
          </section>

          <div class="espacamento-email">
            <p class="email-detalhes"></p>
          </div>
          <div>
            <BaseButton @click="visualizaEventos(broadcast.id)" variant="primary">
              Eventos do Broadcast
            </BaseButton>
          </div>
        </div>
        <div style="width: 100%">
          <iframe
            :style="broadcast.status >= 1 ? '' : 'height: 500px;'"
            class="card-email-modal-wizard"
            :srcdoc="broadcast.html"
          ></iframe>
        </div>
      </div>
      <div class="espaceNewMail mt-3" v-if="broadcast.status === 5">
        <p class="preview-text">Ocorreu um erro na template do e-mail</p>
      </div>
    </div>
    <template v-slot:footer="{ }">
      <BaseButton variant="link-info" class="mr-4" @click="closeModal">
        Fechar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
export default {
  props: {
    broadcast: Object,
  },
  data() {
    return {
      selectedAction: null,
      optionsAction: [
        { value: null, text: "Envio por ação" },
        { value: "email_click", text: "Clicou" },
        { value: "email_open", text: "Abriu" },
        { value: "email_not_click", text: "Não clicou" },
        { value: "email_not_open", text: "Não abriu" },
      ],
    };
  },
  methods: {
    closeModal(){
      this.$bvModal.hide('bv-modal-detalhe')
    },
    visualizaEventos(id) {
      // this.$router.push("/dynamicRoute/apps/broadcast/lista/eventos?email=" + id);
      this.$router.push({
        name: "Lista de Eventos",
        query: {
          email: id,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.broadcast-html {
  padding: 50px !important;
  .flex-2 {
    display: flex;
    justify-content: space-between;
  }
  .espaceNewMail {
    margin-top: -20px;
    display: flex;
    padding-left: 3px;
    padding-right: 3px;
  }

  .pointer-cancel {
    border-radius: 50%;
    background: #ff8d00;
    box-shadow: 0px 0px 10px rgba(201, 201, 201, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-process {
    border-radius: 50%;
    background: #333;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-enviado {
    border-radius: 50%;
    background: var(----greenn);
    box-shadow: 0px 0px 10px rgba(0, 228, 160, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-sairam {
    border-radius: 50%;
    background: #2ed7ee;
    box-shadow: 0px 0px 10px rgba(0, 228, 160, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-aberto {
    border-radius: 50%;
    background: #ffcb37;
    box-shadow: 0px 0px 10px rgba(255, 203, 55, 0.5);
    width: 8px;
    height: 8px;
  }

  .preview-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #333;
    margin-top: -40px;
  }

  .pointer-clicados {
    border-radius: 50%;
    background: #3751ff;
    box-shadow: 0px 0px 10px rgba(46, 215, 238, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-dropped {
    border-radius: 50%;
    background: var(--greenn);
    box-shadow: 0px 0px 10px rgba(181, 185, 197, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-bounce {
    border-radius: 50%;
    background: #5d21d2;
    box-shadow: 0px 0px 10px rgba(93, 33, 210, 0.5);
    width: 8px;
    height: 8px;
  }

  .pointer-spam {
    border-radius: 50%;
    background: #ff0c37;
    box-shadow: 0px 0px 10px rgba(255, 12, 55, 0.5);
    width: 8px;
    height: 8px;
  }

  .flex {
    display: flex;
  }

  .email-detalhes {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--greenn);
    margin-bottom: 43px;
  }

  .espacamento-email {
    margin-top: 20px;
  }

  .number-metrica {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    letter-spacing: 0.2px !important;
    color: #333 !important;
    margin-left: 5px !important;
    line-height: 8px !important;
    width: max-content !important;
  }

  .title-metrica2 {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    letter-spacing: 0.2px !important;
    color: #333333 !important;
  }

  .card-detalhes {
    width: 600px;
    height: 391px;
    background: #ffffff;
    margin-top: 20px;
  }

  .card-email-modal-wizard {
    height: 391px;
    background: #ffffff;
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
  }

  .title-card {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    margin-bottom: 30px;
  }

  .content {
    margin-left: auto;
    margin-right: auto;
    /* width: 60%; */
    width: 800px;
  }

  .fonte-detalhe {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    height: 30px;
  }

  .fonte-data {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #818181;
  }
}

.grid-metrica {
  display: grid;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
